export const GET_GENRES_STATED = "GET_GENRES_STATED";
export const GET_GENRES = "GET_GENRES";
export const GET_GENRES_ENDED = "GET_GENRES_ENDED";
export const ADD_GENRE_STATED = "ADD_GENRE_STARTED";
export const ADD_GENRE = "ADD_GENRE";
export const ADD_GENRE_ENDED = "ADD_GENRE_ENDED";
export const EDIT_GENRE_STATED = "EDIT_GENRE_STATED";
export const EDIT_GENRE = "EDIT_GENRE";
export const EDIT_GENRE_ENDED = "EDIT_GENRE_ENDED";
export const GET_GENRE = "GET_GENRE";
export const GET_GENRE_STATED = "GET_GENRE_STATED";
export const GET_GENRE_ENDED = "GET_GENRE_ENDED";
export const RESET_GENRE = "RESET_GENRE";
export const ERROR_GENRE = "ERROR_GENRE";
export const GET_ALL_GENRES_STATED = "GET_ALL_GENRES_STATED";
export const GET_ALL_GENRES = "GET_ALL_GENRES";
export const GET_ALL_GENRES_ENDED = "GET_ALL_GENRES_ENDED";
