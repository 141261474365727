export const GET_MEDIAS_STATED = "GET_MEDIAS_STATED";
export const GET_MEDIAS = "GET_MEDIAS";
export const GET_MEDIAS_ENDED = "GET_MEDIAS_ENDED";
export const ADD_MEDIA_STATED = "ADD_MEDIA_STARTED";
export const ADD_MEDIA = "ADD_MEDIA";
export const ADD_MEDIA_ENDED = "ADD_MEDIA_ENDED";
export const EDIT_MEDIA_STATED = "EDIT_MEDIA_STATED";
export const EDIT_MEDIA = "EDIT_MEDIA";
export const EDIT_MEDIA_ENDED = "EDIT_MEDIA_ENDED";
export const GET_MEDIA = "GET_MEDIA";
export const GET_MEDIA_STATED = "GET_MEDIA_STATED";
export const GET_MEDIA_ENDED = "GET_MEDIA_ENDED";
export const RESET_MEDIA = "RESET_MEDIA";
export const ERROR_MEDIA = "ERROR_MEDIA";
export const GET_ALL_MEDIAS_STATED = "GET_ALL_MEDIAS_STATED";
export const GET_ALL_MEDIAS = "GET_ALL_MEDIAS";
export const GET_ALL_MEDIAS_ENDED = "GET_ALL_MEDIAS_ENDED";
