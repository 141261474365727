import React from 'react';

function OnlineStreaming() {
  return (
    <section
      className='live-area live-bg fix'
      data-background='img/bg/live_bg.jpg'
      style={{ backgroundImage: `url(/assets/img/bg/live_bg.jpg)` }}
    >
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-xl-5 col-lg-6'>
            <div className='section-title title-style-two mb-25'>
              {/* <span className='sub-title'>ONLINE STREAMING</span> */}
              <h2 className='title'>
                Latest Movie &amp; TV Shows For Friends &amp; Family
              </h2>
            </div>
            <div className='live-movie-content'>
              <p>
                Welcome to IRC Portal, your ultimate destination for up-to-date
                information on the latest movie and TV series releases, complete
                with detailed ratings. We pride ourselves on delivering
                real-time updates, comprehensive synopses, trailers, and expert
                reviews to help you make informed viewing choices. Our
                user-friendly interface ensures seamless navigation, whether
                you're exploring from your desktop or mobile device. At IRC
                Portal, our mission is to empower entertainment enthusiasts with
                the information they need to enhance their viewing experience.
                Join us today and dive into the captivating world of cinema and
                television with confidence. Stay informed, discover new
                favorites, and elevate your entertainment journey with IRC
                Portal.
              </p>
            </div>
          </div>
          <div className='col-xl-7 col-lg-6'>
            <div
              className='live-movie-img wow fadeInRight'
              data-wow-delay='.2s'
              data-wow-duration='1.8s'
            >
              <img src='/assets/img/images/live_img.png' alt />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default OnlineStreaming;
