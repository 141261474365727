import React from 'react';
import Header from '../../components/layout/Header';
import BreadCrumb from '../../components/layout/BreadCrumb';
import Footer from '../../components/layout/Footer';
import Newsletter from '../home/Newsletter';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useResetPassword } from '../../shared/hooks/UseAuth';

function ResetPassword() {
  const params = useParams();
  const [resetUserPassword] = useResetPassword();
  return (
    <div>
      <Header />
      <BreadCrumb title={'Reset Password'} />
      <section
        className='contact-area contact-bg'
        style={{ backgroundImage: `url(/assets/img/bg/contact_bg.jpg)` }}
      >
        <div className='container'>
          <div className='row'>
            <div className='col-xl-5 col-lg-5 mx-auto'>
              <div className='contact-form-wrap'>
                <div className='widget-title mb-50'>
                  <h5 className='title'>Reset Password</h5>
                </div>
                <div className='contact-form'>
                  <Formik
                    initialValues={{
                      password: '',
                      confirm_password: '',
                    }}
                    validationSchema={Yup.object({
                      password: Yup.string().required('Required'),
                      confirm_password: Yup.string().required('Required'),
                    })}
                    onSubmit={async (values, { setSubmitting, resetForm }) => {
                      setSubmitting(true);
                      values.token = params.token;
                      resetUserPassword(values);
                      resetForm();
                      setSubmitting(false);
                    }}
                  >
                    {(formik) => {
                      console.log(formik);
                      return (
                        <Form>
                          <div className='row'>
                            <div className='col-md-12'>
                              <div className='mb-3'>
                                <input
                                  type='password'
                                  placeholder='Password *'
                                  name='password'
                                  onChange={formik.handleChange}
                                  value={formik.values.password}
                                />
                                {formik.errors && formik.errors.email && (
                                  <span className='text-danger'>Required</span>
                                )}
                              </div>
                            </div>
                            <div className='col-md-12'>
                              <div className='mb-3'>
                                <input
                                  type='password'
                                  placeholder='Confirm Password *'
                                  name='confirm_password'
                                  onChange={formik.handleChange}
                                  value={formik.values.confirm_password}
                                />
                                {formik.errors &&
                                  formik.errors.confirm_password && (
                                    <span className='text-danger'>
                                      {formik.errors.confirm_password}
                                    </span>
                                  )}
                              </div>
                            </div>

                            <div className='col-md-12'>
                              <button type='submit' className='btn'>
                                reset
                              </button>
                            </div>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                  <div className='mt-3'>
                    <Link to={'/login'}> Login </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Newsletter />
      <Footer />
    </div>
  );
}

export default ResetPassword;
