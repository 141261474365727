import React from 'react';
import { useAllMedias } from '../../shared/hooks/UseMedia';
import MovieCard from '../../components/movies/MovieCard';
import Slider from 'react-slick';
function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', zIndex: 1 }}
      onClick={onClick}
    />
  );
}

function TopRated() {
  const settings = {
    dots: false,
    arrow: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };
  const [medias_data] = useAllMedias();
  const { medias } = medias_data;
  return (
    <section
      className='top-rated-movie tr-movie-bg'
      data-background='img/bg/tr_movies_bg.jpg'
      style={{ backgroundImage: `url(/assets/img/bg/tr_movies_bg.jpg)` }}
    >
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-lg-8'>
            <div className='section-title text-center mb-50'>
              <h2 className='title'> Movies</h2>
            </div>
          </div>
        </div>

        <div className=''>
          <Slider {...settings}>
            {medias &&
              medias.map((item) => {
                return (
                  <div className='col-xl-12 col-lg-12 col-sm-12 grid-item grid-sizer cat-two'>
                    <MovieCard media={item} />
                  </div>
                );
              })}
          </Slider>
        </div>
      </div>
    </section>
  );
}

export default TopRated;
