import React from 'react';
import { URI } from '../../domain/constant';
import { Link } from 'react-router-dom';

function ProductCard({ product }) {
  return (
    <div className='movie-item movie-item-three mb-50'>
      <div className='movie-poster'>
        {product.media && product.media.length > 0 ? (
          <img src={`${URI}${product.media[0]}`} alt />
        ) : (
          <img src={`/assets/imag/product-placeholder.png`} alt />
        )}

        <ul className='overlay-btn'>
          <li>
            <Link to={`/product/${product.id}`} className='btn'>
              Details
            </Link>
          </li>
        </ul>
      </div>
      <div className='movie-content'>
        <div className='top'>
          <h5 className='title'>
            <Link to={`/product/${product.id}`}>{product.name}</Link>
          </h5>
          <span className='date'>{product.sale_price}</span>
        </div>
        <div className='bottom'>
          <ul>
            <li>
              {product.product_category && (
                <span className='quality'>{product.product_category.name}</span>
              )}
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default ProductCard;
