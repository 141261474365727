import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  addProduct,
  getProducts,
  getProduct,
  editProduct,
  deleteProduct,
  getAllProducts,
  getProductsByCategory,
} from '../../store/actions/product_action';
import _debounce from 'lodash/debounce';
// import { useSelectAllIndustry } from "./UseIndustry";
import * as qs from 'qs';
import api from '../../domain/api';

// Get All Data
export const useAllProducts = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.product);
  const [pageNumber, setPageNumber] = useState(1);
  const [deleteEntry, setDeleteEntry] = useState(null);

  useEffect(() => {
    if (deleteEntry) {
      dispatch(deleteProduct(deleteEntry));
    }
    allQuery();
  }, [deleteEntry, pageNumber, window.location.search]);
  const allQuery = useCallback(
    _debounce(() => {
      dispatch(
        getProducts({
          pageNumber,
        })
      );
    }, 1000),
    []
  );

  useEffect(() => {
    setPageNumber(1);
  }, [window.location.search]);

  return [data, setPageNumber];
};
export const useAllProductsByCategory = (category) => {
  const [products, setProducts] = useState({});

  const getAllProductsByCategory = async (category) => {
    const queryParams = qs.parse(window.location.search.replace('?', ''));
    const query = qs.stringify(queryParams, {
      encodeValuesOnly: true, // prettify url
    });

    const { data } = await api.get(`/products/category/${category}?${query}`);
    setProducts(data);
  };

  return [products, getAllProductsByCategory];
};

// Get Single Data
export const useSingleProduct = (id) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.product);
  useEffect(() => {
    dispatch(getProduct(id));
  }, [id]);
  return [data];
};
