import React from 'react';
import Header from '../../components/layout/Header';
import BreadCrumb from '../../components/layout/BreadCrumb';
import Footer from '../../components/layout/Footer';
import Newsletter from '../home/Newsletter';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useVerifyEmail } from '../../shared/hooks/UseAuth';

function VerifyEmail() {
  const { message } = useVerifyEmail();
  return (
    <div>
      <Header />
      <BreadCrumb title={'Email Verified'} />
      <section
        className='contact-area contact-bg'
        style={{ backgroundImage: `url(/assets/img/bg/contact_bg.jpg)` }}
      >
        <div className='container'>
          <div className='row'>
            <div className='col-xl-5 col-lg-5 mx-auto'>
              <div className='contact-form-wrap'>
                <div className='widget-title mb-50'>
                  <h5 className='title'>Email Verified</h5>
                </div>
                <div className='contact-form'>
                  <div className='mt-3'>{message}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Newsletter />
      <Footer />
    </div>
  );
}

export default VerifyEmail;
