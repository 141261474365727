import { setAlert } from '../store/actions/alert';
import { logout } from '../store/actions/auth';
export const handleError = (err) => async (dispatch) => {
  console.log('ERROR', err.response);
  if (err.response && err.response.data) {
    const errors = err.response.data.message;
    if (errors === 'Not authorized, token failed') {
      console.log('Not Authorized');
      dispatch(logout());
    }
    if (err.response.data && err.response.data.errors) {
      err.response.data.errors.map((item) => {
        dispatch(setAlert(item.message, 'danger'));
      });
    }
  }
};
