import React from 'react';
import { useAllProducts } from '../../shared/hooks/UseProduct';
import ProductCard from '../../components/movies/ProductCard';
import Slider from 'react-slick';
function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', zIndex: 1 }}
      onClick={onClick}
    />
  );
}
function TVSeriesArea() {
  const [product_data] = useAllProducts();
  const { products } = product_data;
  const settings = {
    dots: false,
    arrow: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };
  return (
    <section
      className='tv-series-area tv-series-bg'
      data-background='img/bg/tv_series_bg.jpg'
      style={{ backgroundImage: `url(/assets/img/bg/tv_series_bg.jpg)` }}
    >
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-lg-8'>
            <div className='section-title text-center mb-50'>
              <h2 className='title'> Products</h2>
            </div>
          </div>
        </div>
        <div>
          <Slider {...settings}>
            {products &&
              products.map((product) => {
                return (
                  <div className='col-xl-12 col-lg-12 col-sm-12'>
                    <ProductCard product={product} />
                  </div>
                );
              })}
          </Slider>
        </div>
      </div>
    </section>
  );
}

export default TVSeriesArea;
