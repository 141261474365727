import { Form, Formik } from 'formik';
import React from 'react';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import AlertBox from './AlertBox';

function Footer() {
  return (
    <div>
      <footer>
        <div className='footer-top-wrap'>
          <div className='container'>
            <div className='footer-menu-wrap'>
              <div className='row align-items-center'>
                <div className='col-lg-3'>
                  <div className='footer-logo'>
                    <Link to='/'>
                      <img src='/assets/img/logo/logo.png' alt='IRC Rating' />
                    </Link>
                  </div>
                </div>
                <div className='col-lg-9'>
                  <div className='footer-menu'>
                    <nav>
                      <ul className='navigation'>
                        <li>
                          <Link to='/'>Home</Link>
                        </li>
                        <li>
                          <Link to='/category/644ec22b3395ba7448353f33'>
                            Movies
                          </Link>
                        </li>
                        <li>
                          <Link to='/category/644ec2323395ba7448353f3b'>
                            tv shows
                          </Link>
                        </li>
                        <li>
                          <Link to='/products'>Products</Link>
                        </li>
                        <li>
                          <Link to='/contact-us'>Contact us</Link>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
            <div className='footer-quick-link-wrap'>
              <div className='row align-items-center'>
                <div className='col-md-7'>
                  <div className='quick-link-list'>
                    <ul>
                      <li>
                        <Link to='/about-us'>About Us</Link>
                      </li>
                      <li>
                        <Link to='/terms'>Terms of Use</Link>
                      </li>
                      <li>
                        <Link to='/privacy-policy'>Privacy</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className='col-md-5'>
                  <div className='footer-social'>
                    <ul>
                      <li>
                        <a
                          href='https://www.facebook.com/people/The-IRC-Portal/100091301349931/'
                          target='_blank'
                        >
                          <i className='fab fa-facebook-f' />
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://www.youtube.com/@TheIRCPortal'
                          target='_blank'
                        >
                          <i className='fab fa-youtube' />
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://www.instagram.com/theircportal?igsh=MWp6NjM3Z3NrN2cycg=='
                          target='_blank'
                        >
                          <i className='fab fa-instagram' />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='copyright-wrap'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12 col-md-12'>
                <div className='copyright-text'>
                  <p>Copyright © 2021. All Rights Reserved By IRC Ratings.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <button
        className='scroll-top scroll-to-target'
        onClick={() => window.scrollTo(0, 0)}
      >
        <i className='fas fa-angle-up' />
      </button>
      <AlertBox />
    </div>
  );
}

export default Footer;
