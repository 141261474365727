import {
  GET_MEDIAS_STATED,
  GET_MEDIAS,
  GET_MEDIAS_ENDED,
  ADD_MEDIA_STATED,
  ADD_MEDIA,
  ADD_MEDIA_ENDED,
  EDIT_MEDIA_STATED,
  EDIT_MEDIA,
  EDIT_MEDIA_ENDED,
  GET_MEDIA_STATED,
  GET_MEDIA,
  GET_MEDIA_ENDED,
  GET_ALL_MEDIAS_STATED,
  GET_ALL_MEDIAS,
  GET_ALL_MEDIAS_ENDED,
} from '../types/media_type';

const initialState = {
  category: null,
  genre: null,
  medias_loading: true,
  medias: null,
  page: null,
  pages: null,
  total_medias: 0,

  media: null,
  media_loading: null,

  loading: true,

  media_message: null,
  all_medias: null,
  all_medias_loading: null,
  add_media_loading: true,
  edit_media_loading: true,
};

export const media_reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_MEDIAS_STATED:
      return {
        ...state,
        category: null,
        genre: null,
        medias: null,
        pages: null,
        page: null,
        total_medias: 0,
        medias_loading: true,
      };
    case GET_MEDIAS:
      return {
        ...state,
        category: payload.category,
        genre: payload.genre,
        medias: payload.medias,
        pages: payload.pages,
        page: payload.page,
        total_medias: payload.total_medias,
      };
    case GET_MEDIAS_ENDED:
      return {
        ...state,

        medias_loading: false,
      };
    case GET_ALL_MEDIAS_STATED:
      return {
        ...state,
        all_medias_loading: true,
        all_medias: null,
      };
    case GET_ALL_MEDIAS:
      return {
        ...state,
        all_medias: payload,
      };
    case GET_ALL_MEDIAS_ENDED:
      return {
        ...state,
        all_medias_loading: false,
      };

    case ADD_MEDIA_STATED:
      return {
        ...state,
        media_message: null,
        add_media_loading: true,
      };
    case ADD_MEDIA:
      return {
        ...state,
        media_message: payload,
      };
    case ADD_MEDIA_ENDED:
      return {
        ...state,
        add_media_loading: false,
      };
    case GET_MEDIA_STATED:
      return {
        ...state,
        media: null,
        media_loading: true,
      };
    case GET_MEDIA:
      return {
        ...state,
        media: payload,
      };
    case GET_MEDIA_ENDED:
      return {
        ...state,
        media_loading: false,
      };
    case EDIT_MEDIA_STATED:
      return {
        ...state,
        media_message: null,
        edit_media_loading: true,
      };
    case EDIT_MEDIA:
      return {
        ...state,
        media_message: payload,
      };
    case EDIT_MEDIA_ENDED:
      return {
        ...state,
        edit_media_loading: false,
      };

    default:
      return state;
  }
};
