import React, { useEffect } from 'react';
import RatingCard from './RatingCard';
import { useProfileAuth } from '../../shared/hooks/UseAuth';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import Rating from 'react-rating';
import {
  useCreateRating,
  useRatingByMedia,
} from '../../shared/hooks/UseRating';
import { Link, useParams } from 'react-router-dom';

function RatingComponent({ setAverageRatings }) {
  const params = useParams();
  const [user, isAuthenticated] = useProfileAuth();
  const [data, addData] = useCreateRating();
  const [rating_data] = useRatingByMedia(params.id);
  const { rating, rating_loading } = rating_data;
  useEffect(() => {
    if (rating) {
      const mapData = rating.map((item) => Number(item.rating));
      const averageRatingData =
        mapData.reduce((a, b) => a + b, 0) / rating.length;
      setAverageRatings(
        averageRatingData ? parseFloat(averageRatingData).toFixed(1) : null
      );
    }
  }, [rating]);
  return (
    <div>
      {' '}
      <div className='row'>
        <div className='col-12'>
          <div className='movie-history-wrap'>
            <h3 className='title'>
              <span>Ratings</span>
            </h3>
            {rating &&
              rating.map((item) => {
                return <RatingCard item={item} />;
              })}
          </div>
          {isAuthenticated && user ? (
            <div className='contact-form'>
              <Formik
                initialValues={{
                  rating: '',
                  description: '',
                }}
                validationSchema={Yup.object({
                  rating: Yup.string().required('Required'),
                })}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                  setSubmitting(true);
                  values.user = user.id;
                  values.media = params.id;
                  addData(values);
                  resetForm();
                  setSubmitting(false);
                }}
              >
                {(formik) => {
                  // console.log(formik);
                  return (
                    <Form>
                      <div className='row'>
                        <div className='col-md-12'>
                          <label htmlFor=''> Ratings </label> <br />
                          <Rating
                            emptySymbol='fa fa-star fa-2x'
                            fullSymbol='fa fa-star fa-2x star-fill'
                            fractions={2}
                            stop={10}
                            onChange={(value) =>
                              formik.setFieldValue('rating', value)
                            }
                            initialRating={formik.values.rating}
                            value={formik.values.rating}
                          />
                        </div>
                        <div className='col-md-12 mt-3'>
                          <label htmlFor=''> Review </label>
                          <textarea
                            name='message'
                            placeholder='Type Your Message...'
                            onChange={(e) =>
                              formik.setFieldValue(
                                'description',
                                e.target.value
                              )
                            }
                            value={formik.values.description}
                          />
                        </div>
                        <button className='btn'>Give Review</button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          ) : (
            <div>
              <Link className='btn' to='/login'>
                {' '}
                Login to give Ratings{' '}
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default RatingComponent;
