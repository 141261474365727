import React from 'react';
import Header from '../../components/layout/Header';
import BreadCrumb from '../../components/layout/BreadCrumb';
import Footer from '../../components/layout/Footer';
import Newsletter from '../home/Newsletter';
import { Link } from 'react-router-dom';
import { useLogoutUser, useProfileAuth } from '../../shared/hooks/UseAuth';
import { useAllRatingsByAccount } from '../../shared/hooks/UseRating';
import RatingCard from '../../components/ratings/RatingCard';

function MyAccount() {
  const [user] = useProfileAuth();
  const [logoutUser] = useLogoutUser();
  const [ratings_data] = useAllRatingsByAccount();
  const { ratings } = ratings_data;
  console.log('Ratings', ratings);
  return (
    <div>
      <Header />
      <BreadCrumb title={'My Account'} />
      <section
        className='contact-area contact-bg'
        style={{ backgroundImage: `url(/assets/img/bg/contact_bg.jpg)` }}
      >
        <div className='container'>
          <div className='row'>
            <div className='col-xl-5 col-lg-5 mx-auto'>
              <div className='contact-form-wrap'>
                <div className='widget-title mb-50'>
                  <h5 className='title'>My Account</h5>
                </div>
                {user && !user.is_verified && (
                  <div className='alert alert-danger' role='alert'>
                    Please verify your email address. You had received an email
                    for email verification. Click on the verify link.
                  </div>
                )}
                <div className='contact-form'>
                  <h3> Welcome {user && user.name} </h3>
                  <p>
                    {' '}
                    Phone:{user && user.country_code} {user && user.phone}{' '}
                  </p>
                  <p> Email: {user && user.email} </p>
                  <div className='col-md-12'>
                    <button onClick={() => logoutUser()} className='btn'>
                      Logout
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-12'>
              <div className='movie-history-wrap'>
                <h3 className='title'>
                  <span>Ratings Given</span>
                </h3>
                {ratings &&
                  ratings.map((item) => {
                    return <RatingCard item={item} />;
                  })}
              </div>
            </div>
          </div>
        </div>
      </section>

      <Newsletter />
      <Footer />
    </div>
  );
}

export default MyAccount;
