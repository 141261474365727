import api from '../../domain/api';
import {
  GET_GENRES_STATED,
  GET_GENRES,
  GET_GENRES_ENDED,
  ADD_GENRE_STATED,
  ADD_GENRE,
  ADD_GENRE_ENDED,
  EDIT_GENRE_STATED,
  EDIT_GENRE,
  EDIT_GENRE_ENDED,
  GET_GENRE_STATED,
  GET_GENRE,
  GET_GENRE_ENDED,
  GET_ALL_GENRES_STATED,
  GET_ALL_GENRES,
  GET_ALL_GENRES_ENDED,
} from '../types/genre_type';
import * as qs from 'qs';
import { handleError } from '../../shared/handleError';
import { setAlert } from './alert';

export const addGenre = (formData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADD_GENRE_STATED,
    });
    const { data } = await api.post(`/genres/add`, formData);
    dispatch({
      type: ADD_GENRE,
      payload: data,
    });
    dispatch({
      type: ADD_GENRE_ENDED,
    });
  } catch (error) {
    dispatch({
      type: ADD_GENRE_ENDED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getGenres =
  ({ pageNumber = '' }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: GET_GENRES_STATED,
      });
      const queryParams = qs.parse(window.location.search.replace('?', ''));
      const query = qs.stringify(queryParams, {
        encodeValuesOnly: true, // prettify url
      });

      const { data } = await api.get(`/genres`);

      dispatch({
        type: GET_GENRES,
        payload: data,
      });
      dispatch({
        type: GET_GENRES_ENDED,
      });
    } catch (error) {
      dispatch({
        type: GET_GENRES_ENDED,
      });
      dispatch(handleErrorLocal(error));
      dispatch(handleError(error));
    }
  };
export const getGenre = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_GENRE_STATED,
    });
    const { data } = await api.get(`/genres/${id}`);

    dispatch({
      type: GET_GENRE,
      payload: data,
    });
    dispatch({
      type: GET_GENRE_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_GENRE_STATED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const editGenre = (id, formData) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_GENRE_STATED,
    });
    const { data } = await api.put(`/genres/${id}`, formData);
    dispatch({
      type: EDIT_GENRE,
      payload: data,
    });
    dispatch({
      type: EDIT_GENRE_ENDED,
    });
  } catch (error) {
    dispatch({
      type: EDIT_GENRE_ENDED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const deleteGenre = (id) => async (dispatch) => {
  try {
    const { data } = await api.delete(`/genres/${id}`);
    dispatch(setAlert('Genre Deleted Successfully', 'success'));
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getAllGenres =
  ({ term, value }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: GET_ALL_GENRES_STATED,
      });
      const { data } = await api.get(`/genres/all?term=${term}&value=${value}`);

      dispatch({
        type: GET_ALL_GENRES,
        payload: data,
      });
      dispatch({
        type: GET_ALL_GENRES_ENDED,
      });
    } catch (error) {
      dispatch({
        type: GET_ALL_GENRES_ENDED,
      });
      dispatch(handleErrorLocal(error));
      dispatch(handleError(error));
    }
  };

export const handleErrorLocal = () => async (dispatch) => {};
