import React, { useEffect } from 'react';
// Import css files
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-loading-skeleton/dist/skeleton.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store/store';
import Home from './containers/home/Home';
import { loadUser } from './store/actions/auth';
import setAuthToken from './domain/setAuthToken';
import BeforeLoginRoutes from './shared/Routes/BeforeLoginRoutes';
import PrivateRoutes from './shared/Routes/PrivateRoutes';
import CategoryArchieve from './containers/movies/CategoryArchieve';
import AllTVShows from './containers/tvshows/AllTVShows';
import SingleMovie from './containers/movies/SingleMovie';
import ContactUs from './containers/policies/ContactUs';
import AllBlogs from './containers/blogs/AllBlogs';
import SingleBlog from './containers/blogs/SingleBlog';
import Privacy from './containers/policies/Privacy';
import AboutUs from './containers/about/AboutUs';
import Terms from './containers/policies/Terms';
import SingleTVShow from './containers/tvshows/SingleTVShow';
import AllProducts from './containers/products/AllProducts';
import SingleProduct from './containers/products/SingleProduct';
import Signup from './containers/auth/Signup';
import Login from './containers/auth/Login';
import ForgetPassword from './containers/auth/ForgetPassword';
import ResetPassword from './containers/auth/ResetPassword';
import MyAccount from './containers/auth/MyAccount';
import GenreArchieve from './containers/movies/GenreArchieve';
import ProductArchieve from './containers/products/ProductArchieve';
import VerifyEmail from './containers/auth/VerifyEmail';
import Search from './containers/search/Search';

function App() {
  useEffect(() => {
    //First we have to bring(get that) token, which is saved in local storage
    const token = localStorage.getItem('token');
    //then we will pass that token in setAuthToken method
    setAuthToken(token);
    store.dispatch(loadUser());
  }, []);

  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <Route exact path='/' component={Home} />
          <BeforeLoginRoutes exact path='/sign-up' component={Signup} />
          <BeforeLoginRoutes exact path='/login' component={Login} />
          <BeforeLoginRoutes
            exact
            path='/forget-password'
            component={ForgetPassword}
          />
          <Route
            exact
            path='/reset-password/:token'
            component={ResetPassword}
          />
          <Route exact path='/verify-email/:token' component={VerifyEmail} />
          <PrivateRoutes exact path='/my-account' component={MyAccount} />
          <Route
            exact
            path='/category/:category'
            component={CategoryArchieve}
          />
          <Route exact path='/search' component={Search} />
          <Route
            exact
            path='/category/:category/:genre'
            component={GenreArchieve}
          />
          <Route exact path='/media/:id' component={SingleMovie} />

          <Route exact path='/products' component={AllProducts} />
          <Route exact path='/products/:category' component={ProductArchieve} />
          <Route exact path='/product/:id' component={SingleProduct} />
          <Route exact path='/contact-us' component={ContactUs} />
          <Route exact path='/privacy-policy' component={Privacy} />
          <Route exact path='/terms' component={Terms} />
          <Route exact path='/about-us' component={AboutUs} />
          <Route exact path='/blogs' component={AllBlogs} />
          <Route exact path='/blogs/:id' component={SingleBlog} />

          {/* <Route component={PageNotFound} /> */}
        </Switch>
      </Router>
    </Provider>
  );
}

export default App;
