import React from 'react';
import Header from '../../components/layout/Header';
import BreadCrumb from '../../components/layout/BreadCrumb';
import Newsletter from '../home/Newsletter';
import Footer from '../../components/layout/Footer';

function Terms() {
  return (
    <div>
      <Header />
      <BreadCrumb
        title={'Terms & Conditions'}
        main_heading={'Terms & Conditions'}
      />
      <section
        className='blog-details-area blog-bg'
        data-background='img/bg/blog_bg.jpg'
        style={{ backgroundImage: `url(/assets/img/bg/blog_bg.jpg)` }}
      >
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='blog-post-item blog-details-wrap'>
                <div className='blog-post-content'>
                  <p>
                    Welcome to The IRC Portal (hereinafter referred to as "we",
                    "us", or "our"). These Terms and Conditions govern your
                    access to and use of our website located at{' '}
                    <a href='https://theircportal.com'>theircportal.com</a> (the
                    "Website"). By accessing or using the Website, you agree to
                    be bound by these Terms and Conditions.
                  </p>
                  <h2>1. Use of the Website:</h2>
                  <h3>1.1 Acceptance:</h3>
                  <p>
                    By accessing or using the Website, you agree to comply with
                    these Terms and Conditions. If you do not agree with any
                    part of these Terms and Conditions, you may not use our
                    Website.
                  </p>
                  <h3>1.2 Age Requirement:</h3>
                  <p>
                    You must be at least 13 years old to use our Website. If you
                    are under 13 years old, you may not use our Website.
                  </p>
                  <h2>2. User Accounts:</h2>
                  <h3>2.1 Registration:</h3>
                  <p>
                    In order to access certain features of the Website, you may
                    be required to register for an account. When registering for
                    an account, you agree to provide accurate and complete
                    information.
                  </p>
                  <h3>2.2 Account Security:</h3>
                  <p>
                    You are responsible for maintaining the security of your
                    account credentials and for any activities that occur under
                    your account. You agree to notify us immediately of any
                    unauthorized use of your account or any other security
                    breach.
                  </p>
                  <h2>3. Content:</h2>
                  <h3>3.1 User-generated Content:</h3>
                  <p>
                    You may have the opportunity to submit or post content on
                    our Website, including ratings and reviews of movies and TV
                    shows. By submitting or posting content, you represent and
                    warrant that you have the right to do so and that the
                    content is accurate and does not violate any third-party
                    rights or applicable laws.
                  </p>
                  <h3>3.2 Our Content:</h3>
                  <p>
                    All content on our Website, including text, graphics, logos,
                    images, and software, is the property of The IRC Portal or
                    its licensors and is protected by copyright and other
                    intellectual property laws.
                  </p>
                  <h2>4. Prohibited Activities:</h2>
                  <h3>4.1 You agree not to:</h3>
                  <p>
                    (a) Use our Website for any unlawful purpose;
                    <br />
                    (b) Post or transmit any content that is infringing,
                    defamatory, obscene, or otherwise objectionable;
                    <br />
                    (c) Interfere with or disrupt the operation of our Website
                    or servers; or
                    <br />
                    (d) Attempt to gain unauthorized access to any part of our
                    Website or systems.
                  </p>
                  <h2>5. Limitation of Liability:</h2>
                  <h3>5.1 Disclaimer:</h3>
                  <p>
                    To the fullest extent permitted by law, we disclaim all
                    warranties, express or implied, regarding the accuracy,
                    completeness, or reliability of any content available on our
                    Website.
                  </p>
                  <h3>5.2 Limitation of Liability:</h3>
                  <p>
                    We shall not be liable for any direct, indirect, incidental,
                    special, or consequential damages arising out of or in any
                    way connected with your use of our Website or the content
                    contained therein.
                  </p>
                  <h2>6. Indemnification:</h2>
                  <p>
                    You agree to indemnify and hold harmless The IRC Portal and
                    its affiliates, officers, directors, employees, and agents
                    from and against any and all claims, liabilities, damages,
                    losses, or expenses, including legal fees, arising out of or
                    in any way connected with your use of our Website or
                    violation of these Terms and Conditions.
                  </p>
                  <h2>7. Changes to Terms and Conditions:</h2>
                  <p>
                    We reserve the right to update or modify these Terms and
                    Conditions at any time without prior notice. Any changes
                    will be effective immediately upon posting the updated Terms
                    and Conditions on our Website. Your continued use of our
                    Website after any such changes constitutes your acceptance
                    of the revised Terms and Conditions.
                  </p>
                  <h2>8. Governing Law:</h2>
                  <p>
                    These Terms and Conditions shall be governed by and
                    construed in accordance with the laws of [Jurisdiction],
                    without regard to its conflict of law principles.
                  </p>
                  <h2>9. Contact Us:</h2>
                  <p>
                    If you have any questions or concerns about these Terms and
                    Conditions, please contact us at{' '}
                    <a href='mailto:contact@theirportal.com'>
                      contact@theirportal.com
                    </a>
                    .
                  </p>
                  <p>Thank you for using The IRC Portal!</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Newsletter />
      <Footer />
    </div>
  );
}

export default Terms;
