import React from 'react';
import { Link } from 'react-router-dom';

function BreadCrumb({
  title,
  main_heading,
  main_heading_link,
  sub_heading,
  sub_heading_link,
}) {
  return (
    <section
      className='breadcrumb-area breadcrumb-bg'
      style={{ backgroundImage: `url(/assets/img/bg/breadcrumb_bg.jpg)` }}
    >
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <div className='breadcrumb-content'>
              <h2 className='title'>
                <span> {title} </span>
              </h2>
              <nav aria-label='breadcrumb'>
                <ol className='breadcrumb'>
                  <li className='breadcrumb-item'>
                    <Link to='/'>Home</Link>
                  </li>
                  {main_heading && (
                    <li className='breadcrumb-item active' aria-current='page'>
                      {main_heading_link ? (
                        <Link to={main_heading_link}>{main_heading}</Link>
                      ) : (
                        <> {main_heading}</>
                      )}
                    </li>
                  )}
                  {sub_heading && (
                    <li className='breadcrumb-item active' aria-current='page'>
                      {sub_heading_link ? (
                        <Link to={sub_heading_link}>{sub_heading}</Link>
                      ) : (
                        <> {sub_heading}</>
                      )}
                    </li>
                  )}
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default BreadCrumb;
