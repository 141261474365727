import React from 'react';
import Header from '../../components/layout/Header';
import BreadCrumb from '../../components/layout/BreadCrumb';
import Newsletter from '../home/Newsletter';
import Footer from '../../components/layout/Footer';

function AboutUs() {
  return (
    <div>
      <Header />
      <BreadCrumb title={'About Us'} main_heading={'About Us'} />
      <section
        className='blog-details-area blog-bg'
        data-background='img/bg/blog_bg.jpg'
        style={{ backgroundImage: `url(/assets/img/bg/blog_bg.jpg)` }}
      >
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='blog-post-item blog-details-wrap'>
                <div className='blog-post-content'>
                  <p>
                    Welcome to IRC Portal, your ultimate destination for honest
                    and insightful reviews and ratings. We are passionate about
                    providing a platform where users can share their experiences
                    and opinions on movies, series, products, and more. Our goal
                    is to empower you with the information you need to make
                    informed decisions.
                  </p>
                  <p>
                    At IRC Portal, we believe in the power of user-generated
                    content. We encourage our community to actively participate
                    by rating and reviewing their favorite movies, series, and
                    products. Your voice matters, and we value the diversity of
                    opinions that enrich our platform.
                  </p>

                  <p>
                    Whether you're searching for the latest blockbuster,
                    binge-worthy TV shows, or the perfect product, our
                    user-driven ratings and reviews will guide you in finding
                    the best options. Join us on IRC Portal and become part of a
                    dynamic community that helps others make better choices.
                    Start sharing your thoughts and discover new favorites
                    today.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Newsletter />
      <Footer />
    </div>
  );
}

export default AboutUs;
