import React from 'react';
import Header from '../../components/layout/Header';
import BreadCrumb from '../../components/layout/BreadCrumb';
import Newsletter from '../home/Newsletter';
import Footer from '../../components/layout/Footer';
import { useSingleBlog } from '../../shared/hooks/UseBlog';
import { useParams } from 'react-router-dom';
import { URI } from '../../domain/constant';
import moment from 'moment';

function SingleBlog() {
  const params = useParams();
  const [data] = useSingleBlog(params.id);
  const { blog, blog_loading } = data;
  return (
    <div>
      <Header />
      <BreadCrumb
        title={blog && blog.title}
        main_heading={'Blogs'}
        main_heading_link={'/blogs'}
        sub_heading={blog && blog.title}
      />
      {blog && (
        <section
          className='blog-details-area blog-bg'
          data-background='img/bg/blog_bg.jpg'
          style={{ backgroundImage: `url(/assets/img/bg/blog_bg.jpg)` }}
        >
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <div className='blog-post-item blog-details-wrap'>
                  <div className='blog-post-thumb'>
                    <img
                      src={`${URI}${blog.featured_image}`}
                      style={{ width: '90%' }}
                    />
                  </div>
                  <div className='blog-post-content'>
                    <div className='blog-details-top-meta'>
                      <span className='date'>
                        <i className='far fa-clock' />{' '}
                        {blog.createdAt &&
                          moment(blog.createdAt).format('MMM DD, YYYY')}
                      </span>
                    </div>
                    <h2 className='title'>{blog.title}</h2>
                    <div
                      dangerouslySetInnerHTML={{ __html: blog.description }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      <Newsletter />
      <Footer />
    </div>
  );
}

export default SingleBlog;
