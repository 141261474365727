import React from 'react';
import Header from '../../components/layout/Header';
import BreadCrumb from '../../components/layout/BreadCrumb';
import Newsletter from '../home/Newsletter';
import Footer from '../../components/layout/Footer';

function Privacy() {
  return (
    <div>
      <Header />
      <BreadCrumb title={'Privacy Policy'} main_heading={'Privacy Policy'} />
      <section
        className='blog-details-area blog-bg'
        data-background='img/bg/blog_bg.jpg'
        style={{ backgroundImage: `url(/assets/img/bg/blog_bg.jpg)` }}
      >
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='blog-post-item blog-details-wrap'>
                <div className='blog-post-content'>
                  <p>
                    Welcome to The IRC Portal (hereinafter referred to as "we",
                    "us", or "our"). We are committed to protecting your privacy
                    and ensuring the security of your personal information. This
                    Privacy Policy outlines how we collect, use, share, and
                    protect your personal information when you use our website
                    located at{' '}
                    <a href='https://theircportal.com'>theircportal.com</a> (the
                    "Website").
                  </p>
                  <h2>1. Information We Collect:</h2>
                  <h3>1.1 Personal Information:</h3>
                  <p>
                    When you use our Website, we may collect personal
                    information that you voluntarily provide to us, such as your
                    name, email address, and any other information you choose to
                    provide when registering an account or interacting with our
                    Website.
                  </p>
                  <h3>1.2 Usage Information:</h3>
                  <p>
                    We may also collect information about how you access and use
                    our Website, including your IP address, browser type, device
                    information, and usage patterns.
                  </p>
                  <h2>2. How We Use Your Information:</h2>
                  <h3>2.1 Providing Services:</h3>
                  <p>
                    We use the information we collect to provide, maintain, and
                    improve our Website, including delivering the content and
                    features you request, and providing customer support.
                  </p>
                  <h3>2.2 Personalization:</h3>
                  <p>
                    We may use your information to personalize your experience
                    on our Website, such as showing you personalized
                    recommendations and content based on your viewing history
                    and preferences.
                  </p>
                  <h3>2.3 Communication:</h3>
                  <p>
                    We may use your email address to send you updates,
                    newsletters, promotional offers, and other communications
                    related to our services. You may opt-out of receiving these
                    communications at any time by following the unsubscribe
                    instructions provided in the emails.
                  </p>
                  <h3>2.4 Analytics:</h3>
                  <p>
                    We may use third-party analytics tools to analyze usage
                    patterns on our Website and to improve our services.
                  </p>
                  <h2>3. Sharing of Information:</h2>
                  <h3>3.1 Service Providers:</h3>
                  <p>
                    We may share your information with third-party service
                    providers who assist us in operating our Website, conducting
                    our business, or servicing you.
                  </p>
                  <h3>3.2 Legal Compliance:</h3>
                  <p>
                    We may disclose your information to comply with applicable
                    laws, regulations, legal processes, or governmental
                    requests.
                  </p>
                  <h3>3.3 Business Transfers:</h3>
                  <p>
                    In the event of a merger, acquisition, reorganization, or
                    sale of all or a portion of our assets, your information may
                    be transferred as part of the transaction.
                  </p>
                  <h2>4. Data Security:</h2>
                  <p>
                    We take reasonable measures to protect your personal
                    information from unauthorized access, disclosure,
                    alteration, and destruction. However, no method of
                    transmission over the internet or electronic storage is 100%
                    secure, and we cannot guarantee the absolute security of
                    your information.
                  </p>
                  <h2>5. Your Choices:</h2>
                  <p>
                    You have the right to access, update, or delete your
                    personal information. You may also choose to disable cookies
                    in your browser settings, although this may affect your
                    ability to access certain features of our Website.
                  </p>
                  <h2>6. Children's Privacy:</h2>
                  <p>
                    Our Website is not intended for children under the age of
                    13, and we do not knowingly collect personal information
                    from children under the age of 13. If you believe that we
                    may have collected personal information from a child under
                    the age of 13, please contact us immediately.
                  </p>
                  <h2>7. Changes to this Privacy Policy:</h2>
                  <p>
                    We reserve the right to update or modify this Privacy Policy
                    at any time without prior notice. Any changes will be
                    effective immediately upon posting the updated Privacy
                    Policy on our Website. Your continued use of our Website
                    after any such changes constitutes your acceptance of the
                    revised Privacy Policy.
                  </p>
                  <h2>8. Contact Us:</h2>
                  <p>
                    If you have any questions or concerns about this Privacy
                    Policy or our privacy practices, please contact us at{' '}
                    <a href='mailto: support@theircportal.com'>
                      support@theircportal.com
                    </a>
                    .
                  </p>
                  <p>Thank you for using The IRC Portal!</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Newsletter />
      <Footer />
    </div>
  );
}

export default Privacy;
