import React from 'react';
import Header from '../../components/layout/Header';
import Footer from '../../components/layout/Footer';
import Newsletter from '../home/Newsletter';
import BreadCrumb from '../../components/layout/BreadCrumb';
import MovieCard from '../../components/movies/MovieCard';
import Pagination from '../../components/layout/Pagination';
import { useSingleProduct } from '../../shared/hooks/UseProduct';
import { useParams } from 'react-router-dom';
import { URI } from '../../domain/constant';
import SimilarProducts from '../../components/ratings/SimilarProducts';
import RatingComponent from '../../components/ratings/RatingComponentProduct';

function SingleProduct() {
  const params = useParams();
  const [data] = useSingleProduct(params.id);
  const { product } = data;
  return (
    <div>
      <Header />
      {/* <BreadCrumb title={'Movies'} main_heading='Movies' /> */}
      {product && (
        <>
          <section
            className='movie-details-area'
            data-background='img/bg/movie_details_bg.jpg'
            style={{
              backgroundImage: `url(/assets/img/bg/movie_details_bg.jpg)`,
            }}
          >
            <div className='container'>
              <div className='row align-items-center position-relative'>
                <div className='col-xl-3 col-lg-4'>
                  <div className='movie-details-img'>
                    {product.media && product.media.length > 0 ? (
                      <img
                        src={`${URI}${product.media[0]}`}
                        style={{
                          width: '303px',
                          height: '400px',
                          objectFit: 'cover',
                        }}
                      />
                    ) : (
                      <img
                        src={`/assets/imag/product-placeholder.png`}
                        style={{
                          width: '303px',
                          height: '400px',
                          objectFit: 'cover',
                        }}
                      />
                    )}
                  </div>
                </div>
                <div className='col-xl-6 col-lg-8'>
                  <div className='movie-details-content'>
                    <h2>
                      <span> {product.name} </span>
                    </h2>
                    <div className='banner-meta'>
                      <ul>
                        <li className='quality'>
                          <span>
                            {product.product_category &&
                              product.product_category.name}
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div className='section-title'>
                      <span className='sub-title'>Price</span>
                      <h2 className='title'>₹{product.sale_price}</h2>
                    </div>
                    <div className='movie-details-prime'>
                      <ul>
                        <li className='watch'>
                          <a
                            href={product.buy_link}
                            className='btn popup-video'
                            target='_blank'
                          >
                            <i className='fas fa-play' /> Buy Now
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section
            className='episode-area episode-bg'
            data-background='img/bg/episode_bg.jpg'
            style={{ backgroundImage: `url(/assets/img/bg/episode_bg.jpg)` }}
          >
            <div className='container'>
              <div className='row'>
                <div className='col-lg-12'>
                  <div className='movie-episode-wrap'>
                    <div className='episode-top-wrap'>
                      <div className='section-title'>
                        <h2 className='title'>Product Display</h2>
                      </div>
                    </div>
                    <div className='episode-watch-wrap'>
                      <div className='row'>
                        {product.media &&
                          product.media.map((item) => {
                            return (
                              <div className='col-6'>
                                <img
                                  src={`${URI}${item}`}
                                  style={{ width: '90%' }}
                                />
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <RatingComponent />
              </div>
              <div className='row'>
                <div className='col-12'>
                  <div className='movie-history-wrap'>
                    <h3 className='title'>
                      Product <span>Description</span>
                    </h3>
                    <div
                      dangerouslySetInnerHTML={{ __html: product.description }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <SimilarProducts />
        </>
      )}

      <Newsletter />
      <Footer />
    </div>
  );
}

export default SingleProduct;
