export const GET_RATINGS_STATED = "GET_RATINGS_STATED";
export const GET_RATINGS = "GET_RATINGS";
export const GET_RATINGS_ENDED = "GET_RATINGS_ENDED";
export const ADD_RATING_STATED = "ADD_RATING_STARTED";
export const ADD_RATING = "ADD_RATING";
export const ADD_RATING_ENDED = "ADD_RATING_ENDED";
export const EDIT_RATING_STATED = "EDIT_RATING_STATED";
export const EDIT_RATING = "EDIT_RATING";
export const EDIT_RATING_ENDED = "EDIT_RATING_ENDED";
export const GET_RATING = "GET_RATING";
export const GET_RATING_STATED = "GET_RATING_STATED";
export const GET_RATING_ENDED = "GET_RATING_ENDED";
export const RESET_RATING = "RESET_RATING";
export const ERROR_RATING = "ERROR_RATING";
export const GET_ALL_RATINGS_STATED = "GET_ALL_RATINGS_STATED";
export const GET_ALL_RATINGS = "GET_ALL_RATINGS";
export const GET_ALL_RATINGS_ENDED = "GET_ALL_RATINGS_ENDED";
