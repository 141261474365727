import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import * as qs from 'qs';

function Pagination({ data, count, pages, loading }) {
  const history = useHistory();
  const location = useLocation();
  const [page, setPage] = useState(null);

  const handlePageChange = (page) => {
    const queryParams = qs.parse(window.location.search.replace('?', ''));
    queryParams['pageNumber'] = page;
    const query = qs.stringify(queryParams, {
      encodeValuesOnly: true,
    });

    history.push(`${location.pathname}?${query}`);
  };
  useEffect(() => {
    if (pages && count > 0) {
      const queryParams = qs.parse(window.location.search.replace('?', ''));
      let newPage = queryParams.pageNumber;
      let numberToShow = newPage ? (newPage !== page ? Number(newPage) : 1) : 1;
      console.log('numberToShow', numberToShow);
      setPage(numberToShow);
    }
  }, [window.location.search, pages]);
  useEffect(() => {
    console.log(page, pages);
    if (pages && page > pages) {
      const queryParams = qs.parse(window.location.search.replace('?', ''));
      queryParams['pageNumber'] = 1;
      const query = qs.stringify(queryParams, {
        encodeValuesOnly: true,
      });

      history.push(`${location.pathname}?${query}`);
    }
  }, [page, pages]);

  const [pageArray, setPageArray] = useState(null);

  useEffect(() => {
    if (pages) {
      let newArray = Array.from({ length: pages }, (_, i) => i + 1);
      setPageArray(newArray);
    }
  }, [pages]);
  console.log('PAGES', pageArray, pages);
  return (
    <div className='row'>
      <div className='col-12'>
        <div className='pagination-wrap mt-30'>
          <nav>
            <ul>
              <li
                className={
                  page == 1 ? `disabled` : `paginate_button page-item previous`
                }
              >
                <a
                  onClick={() => handlePageChange(page - 1)}
                  style={{ cursor: 'pointer' }}
                >
                  Prev
                </a>
              </li>
              {pages &&
                pageArray &&
                pageArray.map((item, index) => {
                  return (
                    <li
                      key={index}
                      className={page == index + 1 ? 'active' : ''}
                    >
                      <a onClick={() => handlePageChange(index + 1)}>
                        {index + 1}
                      </a>
                    </li>
                  );
                })}

              <li className={page == pages ? ` disabled` : ``}>
                <a
                  onClick={() => handlePageChange(page + 1)}
                  style={{ cursor: 'pointer' }}
                >
                  Next
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
}

export default Pagination;
