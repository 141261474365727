import React from 'react';
import Header from '../../components/layout/Header';
import BreadCrumb from '../../components/layout/BreadCrumb';
import Newsletter from '../home/Newsletter';
import Footer from '../../components/layout/Footer';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useCreateContact } from '../../shared/hooks/UseContact';

function ContactUs() {
  const [data, addData] = useCreateContact();
  return (
    <div>
      <Header />

      <BreadCrumb title={'Contact Us'} main_heading='Contact Us' />
      <section
        className='contact-area contact-bg'
        style={{ backgroundImage: `url(/assets/img/bg/contact_bg.jpg)` }}
      >
        <div className='container'>
          <div className='row'>
            <div className='col-xl-8 col-lg-7'>
              <div className='contact-form-wrap'>
                <div className='widget-title mb-50'>
                  <h5 className='title'>Contact Us</h5>
                </div>
                <div className='contact-form'>
                  <Formik
                    initialValues={{
                      name: '',
                      phone: '',
                      email: '',
                      message: '',
                    }}
                    validationSchema={Yup.object({
                      name: Yup.string().required('Required'),
                      phone: Yup.string().required('Required'),
                      email: Yup.string().required('Required'),
                      message: Yup.string().required('Required'),
                    })}
                    onSubmit={async (values, { setSubmitting, resetForm }) => {
                      setSubmitting(true);
                      addData(values);
                      resetForm();
                      setSubmitting(false);
                    }}
                  >
                    {(formik) => {
                      console.log(formik);
                      return (
                        <Form>
                          <div className='row'>
                            <div className='col-md-6'>
                              <input
                                type='text'
                                name='name'
                                placeholder='You Name *'
                                onChange={formik.handleChange}
                                value={formik.values.name}
                              />
                              {formik.errors && formik.errors.name && (
                                <p className='text-danger'> Required </p>
                              )}
                            </div>
                            <div className='col-md-6'>
                              <input
                                type='text'
                                name='phone'
                                placeholder='You  Phone *'
                                onChange={formik.handleChange}
                                value={formik.values.phone}
                              />
                              {formik.errors && formik.errors.phone && (
                                <p className='text-danger'> Required </p>
                              )}
                            </div>
                            <div className='col-md-12'>
                              <input
                                type='email'
                                name='email'
                                placeholder='You  Email *'
                                onChange={formik.handleChange}
                                value={formik.values.email}
                              />
                              {formik.errors && formik.errors.email && (
                                <p className='text-danger'> Required </p>
                              )}
                            </div>

                            <div className='col-md-12'>
                              <textarea
                                name='message'
                                placeholder='Type Your Message...'
                                onChange={formik.handleChange}
                                value={formik.values.message}
                              />
                              {formik.errors && formik.errors.message && (
                                <p className='text-danger'> Required </p>
                              )}
                              <button className='btn'>Send Message</button>
                            </div>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
            <div className='col-xl-4 col-lg-5'>
              <div className='widget-title mb-50'>
                <h5 className='title'>Information</h5>
              </div>
              <div className='contact-info-wrap'>
                <div className='contact-info-list'>
                  <ul>
                    <li>
                      <div className='icon'>
                        <i className='fas fa-envelope' />
                      </div>
                      <p>
                        <span>Email :</span> support@theircportal.com
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Newsletter />
      <Footer />
    </div>
  );
}

export default ContactUs;
