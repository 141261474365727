import { combineReducers } from 'redux';
import alert from './alert_reducer';
import auth from './auth_reducer';

import { contact_reducer } from './contact_reducer';
import { newsletter_reducer } from './newsletter_reducer';
import { blog_reducer } from './blog_reducer';
import { category_reducer } from './category_reducer';
import { genre_reducer } from './genre_reducer';
import { media_reducer } from './media_reducer';
import { product_reducer } from './product_reducer';
import { productcategory_reducer } from './productcategory_reducer';
import { rating_reducer } from './rating_reducer';
import { banner_reducer } from './banner_reducer';

export default combineReducers({
  alert,
  auth,
  contact: contact_reducer,
  newsletter: newsletter_reducer,
  blog: blog_reducer,
  category: category_reducer,
  genre: genre_reducer,
  media: media_reducer,
  product: product_reducer,
  productcategory: productcategory_reducer,
  rating: rating_reducer,
  banner: banner_reducer,
});
