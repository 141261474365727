import React from 'react';

function ServiceArea() {
  return (
    <section
      className='services-area services-bg'
      data-background='img/bg/services_bg.jpg'
      style={{ backgroundImage: `url(/assets/img/bg/services_bg.jpg)` }}
    >
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-lg-6'>
            <div className='services-img-wrap'>
              <img src='/assets/img/images/services_img.jpg' alt />
            </div>
          </div>
          <div className='col-lg-6'>
            <div className='services-content-wrap'>
              <div className='section-title title-style-two mb-20'>
                <span className='sub-title'>Our Services</span>
                <h2 className='title'>Stream Your Shows Watch Online.</h2>
              </div>
              <p>
                The "Search and Browse" section allows users to search for
                specific products, services, or businesses and browse through
                various categories. In the "Rating and Reviews" section, users
                can find detailed ratings, comments, and feedback from other
                consumers, helping them gauge quality and satisfaction. "User
                Profiles" enable individuals to create accounts, contribute
                their own reviews, and track their activity. "Filters and
                Sorting" options let users customize their searches based on
                criteria like ratings, date, or relevance. Finally, the "Submit
                a Review" section allows users to share their experiences,
                ensuring the portal remains a valuable resource for informed
                decision-making.
              </p>
              <div className='services-list'></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ServiceArea;
