import React from 'react';
import { useAllMediasByCategory } from '../../shared/hooks/UseMedia';
import MovieCard from '../movies/MovieCard';
function SimilarMedias({ category }) {
  const [data] = useAllMediasByCategory(category);
  const { medias, medias_loading } = data;
  return (
    <div>
      <section
        className='tv-series-area tv-series-bg'
        data-background='img/bg/tv_series_bg02.jpg'
        style={{ backgroundImage: `url(/assets/img/bg/tv_series_bg02.jpg)` }}
      >
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-8'>
              <div className='section-title text-center mb-50'>
                <span className='sub-title'>Looking for More</span>
              </div>
            </div>
          </div>
          <div className='row justify-content-center'>
            {medias &&
              medias.map((item) => {
                return (
                  <div className='col-xl-3 col-lg-4 col-sm-6 grid-item grid-sizer cat-two'>
                    <MovieCard media={item} />
                  </div>
                );
              })}
          </div>
        </div>
      </section>
    </div>
  );
}

export default SimilarMedias;
