import React from 'react';
import Header from '../../components/layout/Header';
import BreadCrumb from '../../components/layout/BreadCrumb';
import Newsletter from '../home/Newsletter';
import Footer from '../../components/layout/Footer';
import BlogCard from '../../components/blogs/BlogCard';
import Pagination from '../../components/layout/Pagination';
import { useAllBlogs } from '../../shared/hooks/UseBlog';

function AllBlogs() {
  const [data] = useAllBlogs();
  const { blogs, blogs_loading, total_blogs, pages, page } = data;
  return (
    <div>
      <Header />
      <BreadCrumb title={'Blogs'} main_heading={'Blogs'} />
      <section
        className='blog-area blog-bg'
        data-background='img/bg/blog_bg.jpg'
        style={{ backgroundImage: `url(/assets/img/bg/blog_bg.jpg)` }}
      >
        <div className='container'>
          <div className='row'>
            {blogs &&
              blogs.map((item) => {
                return (
                  <div className='col-lg-6'>
                    <BlogCard blog={item} />
                  </div>
                );
              })}
          </div>
          <Pagination
            data={blogs}
            page={page}
            pages={pages}
            count={total_blogs}
            loading={blogs_loading}
          />
        </div>
      </section>

      <Newsletter />
      <Footer />
    </div>
  );
}

export default AllBlogs;
