import React from 'react';
import Header from '../../components/layout/Header';
import BreadCrumb from '../../components/layout/BreadCrumb';
import Footer from '../../components/layout/Footer';
import Newsletter from '../home/Newsletter';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { useForgetPassword } from '../../shared/hooks/UseAuth';

function ForgetPassword() {
  const [forgetUserPassword] = useForgetPassword();
  return (
    <div>
      <Header />
      <BreadCrumb title={'Forget Password'} main_heading={'Forget Password'} />
      <section
        className='contact-area contact-bg'
        style={{ backgroundImage: `url(/assets/img/bg/contact_bg.jpg)` }}
      >
        <div className='container'>
          <div className='row'>
            <div className='col-xl-5 col-lg-5 mx-auto'>
              <div className='contact-form-wrap'>
                <div className='widget-title mb-50'>
                  <h5 className='title'>Forget Password</h5>
                </div>
                <div className='contact-form'>
                  <Formik
                    initialValues={{
                      email: '',
                    }}
                    validationSchema={Yup.object({
                      email: Yup.string().required('Required'),
                    })}
                    onSubmit={async (values, { setSubmitting, resetForm }) => {
                      setSubmitting(true);
                      forgetUserPassword(values);
                      resetForm();
                      setSubmitting(false);
                    }}
                  >
                    {(formik) => {
                      console.log(formik);
                      return (
                        <Form>
                          <div className='row'>
                            <div className='col-md-12'>
                              <div className='mb-3'>
                                <input
                                  type='text'
                                  placeholder='Email *'
                                  name='email'
                                  onChange={formik.handleChange}
                                  value={formik.values.email}
                                />
                                {formik.errors && formik.errors.email && (
                                  <span className='text-danger'>Required</span>
                                )}
                              </div>
                            </div>

                            <div className='col-md-12'>
                              <button type='submit' className='btn'>
                                Continue
                              </button>
                            </div>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                  <div className='mt-3'>
                    Don't have an account?{' '}
                    <Link to={'/sign-up'}> Sign Up </Link>{' '}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Newsletter />
      <Footer />
    </div>
  );
}

export default ForgetPassword;
