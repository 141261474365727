import api from '../../domain/api';
import {
  GET_RATINGS_STATED,
  GET_RATINGS,
  GET_RATINGS_ENDED,
  ADD_RATING_STATED,
  ADD_RATING,
  ADD_RATING_ENDED,
  EDIT_RATING_STATED,
  EDIT_RATING,
  EDIT_RATING_ENDED,
  GET_RATING_STATED,
  GET_RATING,
  GET_RATING_ENDED,
  GET_ALL_RATINGS_STATED,
  GET_ALL_RATINGS,
  GET_ALL_RATINGS_ENDED,
} from '../types/rating_type';
import * as qs from 'qs';
import { handleError } from '../../shared/handleError';
import { setAlert } from './alert';

export const addRating = (formData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADD_RATING_STATED,
    });
    const { data } = await api.post(`/ratings/add`, formData);
    dispatch({
      type: ADD_RATING,
      payload: data,
    });
    dispatch({
      type: ADD_RATING_ENDED,
    });
  } catch (error) {
    dispatch({
      type: ADD_RATING_ENDED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};

export const getRatingsByAccount =
  ({ pageNumber = '' }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: GET_RATINGS_STATED,
      });
      const queryParams = qs.parse(window.location.search.replace('?', ''));
      const query = qs.stringify(queryParams, {
        encodeValuesOnly: true, // prettify url
      });

      const { data } = await api.get(`/ratings/account?${query}`);
      console.log('Data');
      dispatch({
        type: GET_RATINGS,
        payload: data,
      });
      dispatch({
        type: GET_RATINGS_ENDED,
      });
    } catch (error) {
      dispatch({
        type: GET_RATINGS_ENDED,
      });
      dispatch(handleErrorLocal(error));
      dispatch(handleError(error));
      console.log('Data');
    }
  };

export const getRatings =
  ({ pageNumber = '' }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: GET_RATINGS_STATED,
      });
      const queryParams = qs.parse(window.location.search.replace('?', ''));
      const query = qs.stringify(queryParams, {
        encodeValuesOnly: true, // prettify url
      });

      const { data } = await api.get(`/ratings?${query}`);

      dispatch({
        type: GET_RATINGS,
        payload: data,
      });
      dispatch({
        type: GET_RATINGS_ENDED,
      });
    } catch (error) {
      dispatch({
        type: GET_RATINGS_ENDED,
      });
      dispatch(handleErrorLocal(error));
      dispatch(handleError(error));
    }
  };
export const getRating = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_RATING_STATED,
    });
    const { data } = await api.get(`/ratings/${id}`);

    dispatch({
      type: GET_RATING,
      payload: data,
    });
    dispatch({
      type: GET_RATING_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_RATING_STATED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getRatingByMedia = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_RATING_STATED,
    });
    const { data } = await api.get(`/ratings/media/${id}`);

    dispatch({
      type: GET_RATING,
      payload: data,
    });
    dispatch({
      type: GET_RATING_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_RATING_STATED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getRatingByProduct = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_RATING_STATED,
    });
    const { data } = await api.get(`/ratings/product/${id}`);

    dispatch({
      type: GET_RATING,
      payload: data,
    });
    dispatch({
      type: GET_RATING_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_RATING_STATED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const editRating = (id, formData) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_RATING_STATED,
    });
    const { data } = await api.put(`/ratings/${id}`, formData);
    dispatch({
      type: EDIT_RATING,
      payload: data,
    });
    dispatch({
      type: EDIT_RATING_ENDED,
    });
  } catch (error) {
    dispatch({
      type: EDIT_RATING_ENDED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const deleteRating = (id) => async (dispatch) => {
  try {
    const { data } = await api.delete(`/ratings/${id}`);
    dispatch(setAlert('Rating Deleted Successfully', 'success'));
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getAllRatings =
  ({ term, value }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: GET_ALL_RATINGS_STATED,
      });
      const { data } = await api.get(
        `/ratings/all?term=${term}&value=${value}`
      );

      dispatch({
        type: GET_ALL_RATINGS,
        payload: data,
      });
      dispatch({
        type: GET_ALL_RATINGS_ENDED,
      });
    } catch (error) {
      dispatch({
        type: GET_ALL_RATINGS_ENDED,
      });
      dispatch(handleErrorLocal(error));
      dispatch(handleError(error));
    }
  };

export const handleErrorLocal = () => async (dispatch) => {};
