import React from 'react';
import { useCreateNewsletter } from '../../shared/hooks/UseNewsletter';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

function Newsletter() {
  const { addNewsletterData } = useCreateNewsletter();
  return (
    <section
      className='newsletter-area newsletter-bg'
      data-background='img/bg/newsletter_bg.jpg'
      style={{ backgroundImage: `url("/assets/img/bg/newsletter_bg.jpg")` }}
    >
      <div className='container'>
        <div className='newsletter-inner-wrap'>
          <div className='row align-items-center'>
            <div className='col-lg-6'>
              <div className='newsletter-content'>
                <h4>Subscribe our newsletter.</h4>
                <p>Enter your email to subscribe our newsletter.</p>
              </div>
            </div>
            <div className='col-lg-6'>
              <Formik
                initialValues={{
                  email: '',
                }}
                validationSchema={Yup.object({
                  email: Yup.string().required('Required'),
                })}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                  setSubmitting(true);
                  addNewsletterData(values);
                  resetForm();
                  setSubmitting(false);
                }}
              >
                {(formik) => {
                  // console.log(formik);
                  return (
                    <Form>
                      <div className='newsletter-form'>
                        <input
                          name='email'
                          onChange={formik.handleChange}
                          placeholder='Enter your email'
                        />

                        <button type='submit' className='btn'>
                          get started
                        </button>
                      </div>
                      {formik.errors && formik.errors.email && (
                        <span className='text-danger'>Required</span>
                      )}
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Newsletter;
