import React from 'react';
import Header from '../../components/layout/Header';
import Footer from '../../components/layout/Footer';
import Banner from '../../components/home/Banner';
import UpcomingSection from './UpcomingSection';
import ServiceArea from './ServiceArea';
import TopRated from './TopRated';
import OnlineStreaming from './OnlineStreaming';
import TVSeriesArea from './TVSeriesArea';
import Newsletter from './Newsletter';
import CategoryMovieCard from './CategoryMovieCard';

function Home() {
  return (
    <div>
      <Header />
      <main>
        {/* banner-area */}
        <Banner />
        {/* banner-area-end */}
        {/* up-coming-movie-area */}
        <TopRated />
        {/* up-coming-movie-area-end */}
        <CategoryMovieCard
          category={'64c4a842641fa5ce182664f8'}
          title={' Trailers'}
        />
        <CategoryMovieCard
          category={'644ec2323395ba7448353f3b'}
          title={' Shows & Series'}
        />
        {/* <CategoryMovieCard
          category={'644ec22b3395ba7448353f33'}
          title={'Latest Movies'}
        /> */}

        {/* services-area */}
        {/* <ServiceArea /> */}
        {/* services-area-end */}
        {/* top-rated-movie */}
        {/* top-rated-movie-end */}
        {/* live-area */}
        {/* <OnlineStreaming /> */}
        {/* live-area-end */}
        {/* tv-series-area */}
        <TVSeriesArea />
        {/* tv-series-area-end */}
        {/* newsletter-area */}
        <Newsletter />
        {/* newsletter-area-end */}
      </main>
      <Footer />
    </div>
  );
}

export default Home;
