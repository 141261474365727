import React, { useEffect } from 'react';
import Header from '../../components/layout/Header';
import Footer from '../../components/layout/Footer';
import Newsletter from '../home/Newsletter';
import BreadCrumb from '../../components/layout/BreadCrumb';
import MovieCard from '../../components/movies/MovieCard';
import Pagination from '../../components/layout/Pagination';
import ProductCard from '../../components/movies/ProductCard';
import { useAllProductsByCategory } from '../../shared/hooks/UseProduct';
import { useParams } from 'react-router-dom';

function ProductArchieve() {
  const params = useParams();
  const [data, getAllProductsByCategory] = useAllProductsByCategory(
    params.category
  );
  const {
    products,
    products_loading,
    pages,
    page,
    total_products,
    product_category,
  } = data;

  useEffect(() => {
    if (params.category) {
      getAllProductsByCategory(params.category);
    }
  }, [params.category, window.location.search]);
  return (
    <div>
      <Header />
      <BreadCrumb
        title={product_category && product_category.name}
        main_heading='Products'
      />
      <section
        className='movie-area movie-bg'
        data-background='img/bg/movie_bg.jpg'
        style={{ backgroundImage: `url(/assets/img/bg/movie_bg.jpg)` }}
      >
        <div className='container'>
          <div className='row tr-movie-active'>
            {products &&
              products.map((product) => {
                return (
                  <div className='col-xl-3 col-lg-4 col-sm-6 grid-item grid-sizer cat-two'>
                    <ProductCard product={product} />
                  </div>
                );
              })}
          </div>
          <Pagination
            data={products}
            page={page}
            pages={pages}
            count={total_products}
            loading={products_loading}
          />
        </div>
      </section>

      <Newsletter />
      <Footer />
    </div>
  );
}

export default ProductArchieve;
