import React, { useState } from 'react';
import Header from '../../components/layout/Header';
import Footer from '../../components/layout/Footer';
import Newsletter from '../home/Newsletter';
import BreadCrumb from '../../components/layout/BreadCrumb';
import MovieCard from '../../components/movies/MovieCard';
import Pagination from '../../components/layout/Pagination';
import { useSingleMedia } from '../../shared/hooks/UseMedia';
import { Link, useParams } from 'react-router-dom';
import { URI } from '../../domain/constant';
import RatingComponent from '../../components/ratings/RatingComponent';
import SimilarMedias from '../../components/ratings/SimilarMedias';

function SingleMovie() {
  const params = useParams();
  const [media_data] = useSingleMedia(params.id);
  const { media, media_loading } = media_data;
  const [averageRatings, setAverageRatings] = useState(null);
  return (
    <div>
      <Header />
      {/* <BreadCrumb title={'Movies'} main_heading='Movies' /> */}

      {media && (
        <>
          <section
            className='movie-details-area'
            data-background='img/bg/movie_details_bg.jpg'
            style={{
              backgroundImage: `url(/assets/img/bg/movie_details_bg.jpg)`,
            }}
          >
            <div className='container'>
              <div className='row align-items-center position-relative'>
                <div className='col-xl-3 col-lg-4'>
                  <div className='movie-details-img'>
                    <img
                      src={`${URI}${media.poster_image}`}
                      style={{
                        width: '303px',
                        height: '400px',
                        objectFit: 'cover',
                      }}
                    />
                    {/* <a
                      href='https://www.youtube.com/watch?v=R2gbPxeNk2E'
                      className='popup-video'
                    >
                      <img src='/assets/img/images/play_icon.png' alt />
                    </a> */}
                  </div>
                </div>
                <div className='col-xl-6 col-lg-8'>
                  <div className='movie-details-content'>
                    <h2>{media.name}</h2>
                    <div className='banner-meta'>
                      <ul>
                        <li className='quality'>
                          {media.genre &&
                            media.genre.map((item) => {
                              return <span>{item.name}</span>;
                            })}
                        </li>
                        <li className='category'>
                          {media.category && (
                            <Link to={`/category/${media.category.id}`}>
                              {media.category.name}
                            </Link>
                          )}
                        </li>
                        <li className='release-time'>
                          <span>
                            <i className='far fa-calendar-alt' />{' '}
                            {media.released_year}
                          </span>
                          <span>
                            <i className='far fa-clock' /> {media.runtime}
                          </span>
                          <span>
                            <i className='far fa-star' />{' '}
                            {averageRatings ? averageRatings : 'No Ratings'}
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div
                      dangerouslySetInnerHTML={{ __html: media.about_media }}
                    ></div>
                    <div className='movie-details-prime'>
                      <ul>
                        <li className='watch'>
                          <a
                            href={media.link_to_watch}
                            className='btn popup-video'
                            target='_blank'
                          >
                            <i className='fas fa-play' /> Watch Now
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section
            className='episode-area episode-bg'
            data-background='img/bg/episode_bg.jpg'
            style={{ backgroundImage: `url(/assets/img/bg/episode_bg.jpg)` }}
          >
            <div className='container'>
              <div className='row'>
                <div className='col-lg-8'>
                  <div className='movie-episode-wrap'>
                    <div className='episode-top-wrap'>
                      <div className='section-title'>
                        <span className='sub-title'>Casts</span>
                      </div>
                    </div>
                    <div className='episode-watch-wrap'>
                      <div className='accordion' id='accordionExample'>
                        <div className='card'>
                          <div className='card-header' id='headingOne'>
                            <button
                              className='btn-block text-left'
                              type='button'
                              data-toggle='collapse'
                              data-target='#collapseOne'
                              aria-expanded='true'
                              aria-controls='collapseOne'
                            >
                              <span className='season'>Directors</span>
                            </button>
                          </div>
                          <div
                            id='collapseOne'
                            className='collapse show'
                            aria-labelledby='headingOne'
                            data-parent='#accordionExample'
                          >
                            <div className='card-body'>
                              <ul>
                                {media.director &&
                                  media.director.map((item) => {
                                    return <li>{item.name}</li>;
                                  })}
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className='card'>
                          <div className='card-header' id='headingTwo'>
                            <button
                              className='btn-block text-left collapsed'
                              type='button'
                              data-toggle='collapse'
                              data-target='#collapseTwo'
                              aria-expanded='false'
                              aria-controls='collapseTwo'
                            >
                              <span className='season'>Actors</span>
                            </button>
                          </div>
                          <div
                            id='collapseTwo'
                            className='collapse'
                            aria-labelledby='headingTwo'
                            data-parent='#accordionExample'
                          >
                            <div className='card-body'>
                              <ul>
                                {media.actors &&
                                  media.actors.map((item) => {
                                    return <li>{item.name}</li>;
                                  })}
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className='card'>
                          <div className='card-header' id='headingThree'>
                            <button
                              className='btn-block text-left collapsed'
                              type='button'
                              data-toggle='collapse'
                              data-target='#collapseThree'
                              aria-expanded='false'
                              aria-controls='collapseThree'
                            >
                              <span className='season'>Writers</span>
                            </button>
                          </div>
                          <div
                            id='collapseThree'
                            className='collapse'
                            aria-labelledby='headingThree'
                            data-parent='#accordionExample'
                          >
                            <div className='card-body'>
                              <ul>
                                {media.writers &&
                                  media.writers.map((item) => {
                                    return <li>{item.name}</li>;
                                  })}
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className='card'>
                          <div className='card-header' id='headingFour'>
                            <button
                              className='btn-block text-left collapsed'
                              type='button'
                              data-toggle='collapse'
                              data-target='#collapseFour'
                              aria-expanded='false'
                              aria-controls='collapseFour'
                            >
                              <span className='season'>Producers</span>
                            </button>
                          </div>
                          <div
                            id='collapseFour'
                            className='collapse'
                            aria-labelledby='headingFour'
                            data-parent='#accordionExample'
                          >
                            <div className='card-body'>
                              <ul>
                                {media.producers &&
                                  media.producers.map((item) => {
                                    return <li>{item.name}</li>;
                                  })}
                              </ul>
                            </div>
                          </div>
                        </div>

                        <div>
                          <div className='movie-history-wrap'>
                            <h3 className='title'>
                              Inside <span>Shots</span>
                            </h3>
                            {media.photos &&
                              media.photos.map((item) => {
                                return (
                                  <div>
                                    <img
                                      src={`${URI}${item}`}
                                      style={{ width: '90%' }}
                                    />
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-lg-4'>
                  <div className='episode-img'>
                    <img src={`${URI}${media.poster_image}`} alt />
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <div className='movie-history-wrap'>
                    <h3 className='title'>
                      About <span>{media.name}</span>
                    </h3>

                    <div className='section-title'>
                      <p>
                        <span className='sub-title'>Stroy</span>
                      </p>
                      {/* <h2 className='title'>Watch Full Episode</h2> */}
                    </div>
                    <div
                      dangerouslySetInnerHTML={{ __html: media.story }}
                    ></div>
                  </div>
                </div>
              </div>
              <RatingComponent setAverageRatings={setAverageRatings} />
            </div>
          </section>
          {media.category && <SimilarMedias category={media.category.id} />}
        </>
      )}

      <Newsletter />
      <Footer />
    </div>
  );
}

export default SingleMovie;
