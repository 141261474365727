import React, { useEffect } from 'react';
import Header from '../../components/layout/Header';
import Footer from '../../components/layout/Footer';
import Newsletter from '../home/Newsletter';
import BreadCrumb from '../../components/layout/BreadCrumb';
import MovieCard from '../../components/movies/MovieCard';
import Pagination from '../../components/layout/Pagination';
import { useParams } from 'react-router-dom';
import { useAllMediasByCategoryAndGenre } from '../../shared/hooks/UseMedia';

function GenreArchieve() {
  const params = useParams();
  const [medias_data, getAllMediasByCategory] = useAllMediasByCategoryAndGenre(
    params.category,
    params.genre
  );
  const { medias, medias_loading, total_medias, page, pages, category, genre } =
    medias_data;
  useEffect(() => {
    getAllMediasByCategory(params.category, params.genre);
  }, [params.category, params.genre, window.location.search]);

  return (
    <div>
      <Header />
      <BreadCrumb
        title={genre && genre.name}
        main_heading={category && category.name}
      />
      <section
        className='movie-area movie-bg'
        data-background='img/bg/movie_bg.jpg'
        style={{ backgroundImage: `url(/assets/img/bg/movie_bg.jpg)` }}
      >
        <div className='container'>
          <div className='row align-items-end mb-60'>
            <div className='col-lg-6'>
              <div className='section-title text-center text-lg-left'>
                <span className='sub-title'> {category && category.name} </span>
                <h2 className='title'> {genre && genre.name} </h2>
              </div>
            </div>
          </div>
          <div className='row tr-movie-active'>
            {medias &&
              medias.map((item) => {
                return (
                  <div className='col-xl-3 col-lg-4 col-sm-6 grid-item grid-sizer cat-two'>
                    <MovieCard media={item} />
                  </div>
                );
              })}
          </div>
          <Pagination
            data={medias}
            page={page}
            pages={pages}
            count={total_medias}
            loading={medias_loading}
          />
        </div>
      </section>

      <Newsletter />
      <Footer />
    </div>
  );
}

export default GenreArchieve;
