import React from 'react';
import { URI } from '../../domain/constant';
import { Link } from 'react-router-dom';

function MovieCard({ media }) {
  return (
    <div className='movie-item movie-item-three mb-50'>
      <div className='movie-poster'>
        <img src={`${URI}${media.poster_image}`} />
        <ul className='overlay-btn'>
          <li>
            <Link to={`/media/${media._id}`} className='btn'>
              Details
            </Link>
          </li>
        </ul>
      </div>
      <div className='movie-content'>
        <div className='top'>
          <h5 className='title'>
            <Link to={`/media/${media._id}`}>{media.name}</Link>
          </h5>
          <span className='date'>{media.released_year}</span>
        </div>
        <div>
          Ratings:{' '}
          {media.average_rating ? media.average_rating : 'No Ratings Yet'}
        </div>
        <div className='bottom'>
          <ul>
            {/* <li>
              {media.genre &&
                media.genre.map((item) => {
                  return <span className='quality'>{item.name}</span>;
                })}
            </li> */}
            <li>
              <span className='duration'>
                <i className='far fa-clock' /> {media.runtime}
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default MovieCard;
