import React from 'react';
import Header from '../../components/layout/Header';
import Footer from '../../components/layout/Footer';
import Newsletter from '../home/Newsletter';
import BreadCrumb from '../../components/layout/BreadCrumb';
import MovieCard from '../../components/movies/MovieCard';
import Pagination from '../../components/layout/Pagination';
import ProductCard from '../../components/movies/ProductCard';
import { useAllProducts } from '../../shared/hooks/UseProduct';

function AllProducts() {
  const [data] = useAllProducts();
  const { products, total_products, pages, page, products_loading } = data;
  return (
    <div>
      <Header />
      <BreadCrumb title={'Products'} main_heading='Products' />
      <section
        className='movie-area movie-bg'
        data-background='img/bg/movie_bg.jpg'
        style={{ backgroundImage: `url(/assets/img/bg/movie_bg.jpg)` }}
      >
        <div className='container'>
          <div className='row align-items-end mb-60'>
            <div className='col-lg-6'>
              <div className='section-title text-center text-lg-left'>
                <h2 className='title'>All products</h2>
              </div>
            </div>
          </div>
          <div className='row tr-movie-active'>
            {products &&
              products.map((product) => {
                return (
                  <div className='col-xl-3 col-lg-4 col-sm-6 grid-item grid-sizer cat-two'>
                    <ProductCard product={product} />
                  </div>
                );
              })}
          </div>
          <Pagination
            data={products}
            page={page}
            pages={pages}
            count={total_products}
            loading={products_loading}
          />
        </div>
      </section>

      <Newsletter />
      <Footer />
    </div>
  );
}

export default AllProducts;
