import React from 'react';

function RatingCard({ item }) {
  return (
    <div
      className='movie-review'
      style={{
        borderBottom: '1px solid #29272f',
        marginBottom: '10px',
      }}
    >
      <div>
        <p>
          <div dangerouslySetInnerHTML={{ __html: item.description }}></div>
        </p>
        <p style={{ color: '#e4d804' }}>
          {' '}
          <i className='fa fa-star'></i>
          {item.rating}
        </p>
      </div>
      <div className='section-title'>
        <p>
          <span className='sub-title'>-{item.user && item.user.name}</span>
        </p>
        {/* <h2 className='title'>Watch Full Episode</h2> */}
      </div>
    </div>
  );
}

export default RatingCard;
