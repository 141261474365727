import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  addMedia,
  getMedias,
  getMedia,
  editMedia,
  deleteMedia,
  getAllMedias,
  getMediasByCategory,
  getMediasByCategoryAndGenre,
} from '../../store/actions/media_action';
import _debounce from 'lodash/debounce';
import api from '../../domain/api';
// import { useSelectAllIndustry } from "./UseIndustry";
import * as qs from 'qs';
// Get All Data
export const useAllMedias = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.media);
  const [pageNumber, setPageNumber] = useState(1);
  const [deleteEntry, setDeleteEntry] = useState(null);

  useEffect(() => {
    if (deleteEntry) {
      dispatch(deleteMedia(deleteEntry));
    }
    allQuery();
  }, [deleteEntry, pageNumber, window.location.search]);
  const allQuery = useCallback(
    _debounce(() => {
      dispatch(
        getMedias({
          pageNumber,
        })
      );
    }, 1000),
    []
  );

  useEffect(() => {
    setPageNumber(1);
  }, [window.location.search]);

  const deleteBtnClicked = async (id) => {
    setDeleteEntry(id);
  };

  return [data, setPageNumber, deleteBtnClicked];
};
// Get All Data
export const useAllMediasByCategory = () => {
  const [products, setProducts] = useState({
    medias: null,
    page: null,
    pages: null,
    total_medias: 0,
  });
  const getAllMediasByCategory = async (category) => {
    const queryParams = qs.parse(window.location.search.replace('?', ''));
    const query = qs.stringify(queryParams, {
      encodeValuesOnly: true, // prettify url
    });

    const { data } = await api.get(`/medias/category/${category}/?${query}`);
    setProducts(data);
  };

  return [products, getAllMediasByCategory];
};

// Get By Category
// Get All Data
export const useMediasByCategoryHook = (category) => {
  const [data, setData] = useState(null);
  const getMediasByCategory = async () => {
    const { data } = await api.get(`/medias/category/${category}`);
    setData(data);
  };
  useEffect(() => {
    getMediasByCategory();
  }, [category]);
  return { data };
};

// Get All Data
export const useAllMediasByCategoryAndGenre = () => {
  const [products, setProducts] = useState({
    medias: null,
    page: null,
    pages: null,
    total_medias: 0,
  });
  const getAllMediasByCategory = async (category, genre) => {
    const queryParams = qs.parse(window.location.search.replace('?', ''));
    const query = qs.stringify(queryParams, {
      encodeValuesOnly: true, // prettify url
    });

    const { data } = await api.get(
      `/medias/genre/${category}/${genre}/?${query}`
    );
    setProducts(data);
  };

  return [products, getAllMediasByCategory];
};

// Get Single Data
export const useSingleMedia = (id) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.media);
  useEffect(() => {
    dispatch(getMedia(id));
  }, [id]);
  return [data];
};
