import api from '../../domain/api';
import {
  GET_MEDIAS_STATED,
  GET_MEDIAS,
  GET_MEDIAS_ENDED,
  ADD_MEDIA_STATED,
  ADD_MEDIA,
  ADD_MEDIA_ENDED,
  EDIT_MEDIA_STATED,
  EDIT_MEDIA,
  EDIT_MEDIA_ENDED,
  GET_MEDIA_STATED,
  GET_MEDIA,
  GET_MEDIA_ENDED,
  GET_ALL_MEDIAS_STATED,
  GET_ALL_MEDIAS,
  GET_ALL_MEDIAS_ENDED,
} from '../types/media_type';
import * as qs from 'qs';
import { handleError } from '../../shared/handleError';
import { setAlert } from './alert';

export const addMedia = (formData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADD_MEDIA_STATED,
    });
    const { data } = await api.post(`/medias/add`, formData);
    dispatch({
      type: ADD_MEDIA,
      payload: data,
    });
    dispatch({
      type: ADD_MEDIA_ENDED,
    });
  } catch (error) {
    dispatch({
      type: ADD_MEDIA_ENDED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getMedias =
  ({ pageNumber = '' }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: GET_MEDIAS_STATED,
      });
      const queryParams = qs.parse(window.location.search.replace('?', ''));
      const query = qs.stringify(queryParams, {
        encodeValuesOnly: true, // prettify url
      });

      const { data } = await api.get(`/medias?${query}`);

      dispatch({
        type: GET_MEDIAS,
        payload: data,
      });
      dispatch({
        type: GET_MEDIAS_ENDED,
      });
    } catch (error) {
      dispatch({
        type: GET_MEDIAS_ENDED,
      });
      dispatch(handleErrorLocal(error));
      dispatch(handleError(error));
    }
  };
export const getMediasByCategory =
  ({ category }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: GET_MEDIAS_STATED,
      });
      const queryParams = qs.parse(window.location.search.replace('?', ''));
      const query = qs.stringify(queryParams, {
        encodeValuesOnly: true, // prettify url
      });

      const { data } = await api.get(`/medias/category/${category}/?${query}`);

      dispatch({
        type: GET_MEDIAS,
        payload: data,
      });
      dispatch({
        type: GET_MEDIAS_ENDED,
      });
    } catch (error) {
      dispatch({
        type: GET_MEDIAS_ENDED,
      });
      dispatch(handleErrorLocal(error));
      dispatch(handleError(error));
    }
  };
export const getMediasByCategoryAndGenre =
  ({ category, genre }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: GET_MEDIAS_STATED,
      });
      const queryParams = qs.parse(window.location.search.replace('?', ''));
      const query = qs.stringify(queryParams, {
        encodeValuesOnly: true, // prettify url
      });

      const { data } = await api.get(
        `/medias/genre/${category}/${genre}/?${query}`
      );

      dispatch({
        type: GET_MEDIAS,
        payload: data,
      });
      dispatch({
        type: GET_MEDIAS_ENDED,
      });
    } catch (error) {
      dispatch({
        type: GET_MEDIAS_ENDED,
      });
      dispatch(handleErrorLocal(error));
      dispatch(handleError(error));
    }
  };
export const getMedia = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_MEDIA_STATED,
    });
    const { data } = await api.get(`/medias/${id}`);

    dispatch({
      type: GET_MEDIA,
      payload: data,
    });
    dispatch({
      type: GET_MEDIA_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_MEDIA_STATED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const editMedia = (id, formData) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_MEDIA_STATED,
    });
    const { data } = await api.put(`/medias/${id}`, formData);
    dispatch({
      type: EDIT_MEDIA,
      payload: data,
    });
    dispatch({
      type: EDIT_MEDIA_ENDED,
    });
  } catch (error) {
    dispatch({
      type: EDIT_MEDIA_ENDED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const deleteMedia = (id) => async (dispatch) => {
  try {
    const { data } = await api.delete(`/medias/${id}`);
    dispatch(setAlert('Media Deleted Successfully', 'success'));
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getAllMedias =
  ({ term, value }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: GET_ALL_MEDIAS_STATED,
      });
      const { data } = await api.get(`/medias/all?term=${term}&value=${value}`);

      dispatch({
        type: GET_ALL_MEDIAS,
        payload: data,
      });
      dispatch({
        type: GET_ALL_MEDIAS_ENDED,
      });
    } catch (error) {
      dispatch({
        type: GET_ALL_MEDIAS_ENDED,
      });
      dispatch(handleErrorLocal(error));
      dispatch(handleError(error));
    }
  };

export const handleErrorLocal = () => async (dispatch) => {};
