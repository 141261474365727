import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAllCategorys } from '../../shared/hooks/UseCategory';
import { useAllGenres } from '../../shared/hooks/UseGenre';
import { useAllProductCategorys } from '../../shared/hooks/UseProductCategory';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useProfileAuth } from '../../shared/hooks/UseAuth';

function Header() {
  const location = useLocation();
  const history = useHistory();
  const [user, isAuthenticated] = useProfileAuth();
  useEffect(() => {
    window.scrollTo(0, 0);
    setShowMobileMenu(false);
  }, [location]);
  // console.log('Location', location);
  const [category_data] = useAllCategorys();
  const [genre_data] = useAllGenres();
  const [productcategorys_data] = useAllProductCategorys();
  const { categorys, categorys_loading } = category_data;
  const { genres, genres_loading } = genre_data;
  const { productcategorys, productcategorys_loading } = productcategorys_data;
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const searchSubmit = () => {
    history.push(`/search/?search[name]=${searchTerm}`);
    window.location.reload();
  };

  return (
    <header>
      <div id='sticky-header' className='menu-area transparent-header'>
        <div className='container custom-container'>
          <div className='row'>
            <div className='col-12'>
              <div
                className='mobile-nav-toggler'
                onClick={() => setShowMobileMenu(true)}
              >
                <i className='fas fa-bars' />
              </div>

              <div className='menu-wrap'>
                <nav className='menu-nav show'>
                  <div className='logo'>
                    <Link to='/'>
                      <img src='/assets/img/logo/logo.png' alt='Logo' />
                    </Link>
                  </div>
                  <div className='navbar-wrap main-menu d-none d-lg-flex'>
                    <ul className='navigation'>
                      <li
                        className={location.pathname === '/' ? 'active ' : ''}
                      >
                        <Link to={'/'}>Home</Link>
                      </li>
                      {categorys &&
                        categorys.map((item) => {
                          return (
                            <li
                              className={
                                location.pathname.includes('/movies')
                                  ? 'active '
                                  : ''
                              }
                            >
                              <Link to={`/category/${item._id}`}>
                                {item.name}
                              </Link>
                              <ul className='submenu '>
                                <div className='row'>
                                  {genres &&
                                    genres.map((genre) => {
                                      return (
                                        <li className='col-md-2'>
                                          <Link
                                            to={`/category/${item._id}/${genre._id}`}
                                          >
                                            {genre.name}
                                          </Link>
                                        </li>
                                      );
                                    })}
                                </div>
                              </ul>
                            </li>
                          );
                        })}

                      <li
                        className={
                          location.pathname.includes('/products')
                            ? 'active '
                            : ''
                        }
                      >
                        <Link to='/products'>Products</Link>
                        <ul className='submenu'>
                          <div className='row'>
                            {productcategorys &&
                              productcategorys.map((genre) => {
                                return (
                                  <li className='col-md-2'>
                                    <Link to={`/products/${genre._id}`}>
                                      {genre.name}
                                    </Link>
                                  </li>
                                );
                              })}
                          </div>
                        </ul>
                      </li>
                      <li
                        className={
                          location.pathname.includes('/blogs') ? 'active ' : ''
                        }
                      >
                        <Link to='/blogs'>Blog</Link>
                      </li>
                    </ul>
                  </div>
                  <div className='header-action d-none d-md-block'>
                    <ul>
                      <li className='header-search'>
                        <a
                          href='#'
                          data-toggle='modal'
                          data-target='#search-modal'
                        >
                          <i className='fas fa-search' />
                        </a>
                      </li>
                      {/* <li class="header-lang">
                  <form action="#">
                    <div class="icon"><i class="flaticon-globe"></i></div>
                    <select id="lang-dropdown">
                      <option value="">En</option>
                      <option value="">Au</option>
                      <option value="">AR</option>
                      <option value="">TU</option>
                    </select>
                  </form>
                </li> */}
                      <li class='header-btn'>
                        {isAuthenticated ? (
                          <Link to='/my-account' class='btn'>
                            My Account
                          </Link>
                        ) : (
                          <Link to='/login' class='btn'>
                            Sign In
                          </Link>
                        )}
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
              <div className={showMobileMenu && 'mobile-menu-visible'}>
                <div className='mobile-menu'>
                  <div
                    className='close-btn'
                    onClick={() => setShowMobileMenu(false)}
                  >
                    <i className='fas fa-times' />
                  </div>
                  <nav className='menu-box'>
                    <div className='nav-logo'>
                      <a href='index.html'>
                        <img src='/assets/img/logo/logo.png' alt title />
                      </a>
                    </div>
                    <div className='menu-outer'>
                      <ul className='navigation'>
                        <li
                          className={location.pathname === '/' ? 'active ' : ''}
                        >
                          <Link to={'/'}>Home</Link>
                        </li>
                        {categorys &&
                          categorys.map((item) => {
                            return (
                              <li
                                className={
                                  location.pathname.includes('/movies')
                                    ? 'active '
                                    : ''
                                }
                              >
                                <Link to={`/category/${item._id}`}>
                                  {item.name}
                                </Link>
                                <ul className='submenu'>
                                  <div className='row'>
                                    {genres &&
                                      genres.map((genre) => {
                                        return (
                                          <div className='col-md-4'>
                                            <li>
                                              <Link
                                                to={`/category/${item._id}/${genre._id}`}
                                              >
                                                {genre.name}
                                              </Link>
                                            </li>
                                          </div>
                                        );
                                      })}
                                  </div>
                                </ul>
                              </li>
                            );
                          })}

                        <li
                          className={
                            location.pathname.includes('/products')
                              ? 'active '
                              : ''
                          }
                        >
                          <Link to='/products'>Products</Link>
                          <ul className='submenu'>
                            <div className='row'>
                              {productcategorys &&
                                productcategorys.map((genre) => {
                                  return (
                                    <div className='col-md-4'>
                                      <li>
                                        <Link to={`/products/${genre._id}`}>
                                          {genre.name}
                                        </Link>
                                      </li>
                                    </div>
                                  );
                                })}
                            </div>
                          </ul>
                        </li>
                        <li
                          className={
                            location.pathname.includes('/blogs')
                              ? 'active '
                              : ''
                          }
                        >
                          <Link to='/blogs'>Blog</Link>
                        </li>
                        <li
                          className={
                            location.pathname === '/contact-us' ? 'active ' : ''
                          }
                        >
                          <Link to='/contact-us'>contacts</Link>
                        </li>
                        <li>
                          {isAuthenticated ? (
                            <Link to='/my-account'>My Account</Link>
                          ) : (
                            <Link to='/login'>Sign In</Link>
                          )}
                        </li>
                      </ul>
                      {/*Here Menu Will Come Automatically Via Javascript / Same Menu as in Header*/}
                    </div>
                    <div className='social-links'>
                      <ul className='clearfix'>
                        <li>
                          <a
                            href='https://www.facebook.com/people/The-IRC-Portal/100091301349931/'
                            target='_blank'
                          >
                            <i className='fab fa-facebook-f' />
                          </a>
                        </li>
                        <li>
                          <a
                            href='https://www.youtube.com/@TheIRCPortal'
                            target='_blank'
                          >
                            <i className='fab fa-youtube' />
                          </a>
                        </li>
                        <li>
                          <a
                            href='https://www.instagram.com/theircportal?igsh=MWp6NjM3Z3NrN2cycg=='
                            target='_blank'
                          >
                            <i className='fab fa-instagram' />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </nav>
                </div>
              </div>
              {/* Mobile Menu  */}

              <div className='menu-backdrop' />
              {/* End Mobile Menu */}
              {/* Modal Search */}
              <div
                className='modal fade'
                id='search-modal'
                tabIndex={-1}
                role='dialog'
                aria-hidden='true'
              >
                <div className='modal-dialog' role='document'>
                  <div className='modal-content'>
                    <Formik
                      initialValues={{
                        name: '',
                      }}
                      validationSchema={Yup.object({
                        name: Yup.string().required('Required'),
                      })}
                      onSubmit={async (
                        values,
                        { setSubmitting, resetForm }
                      ) => {
                        setSubmitting(true);

                        resetForm();
                        setSubmitting(false);
                      }}
                    >
                      {(formik) => {
                        // console.log(formik);
                        return (
                          <Form>
                            <div className='form'>
                              <input
                                type='text'
                                placeholder='Search here...'
                                onChange={(e) => setSearchTerm(e.target.value)}
                                value={searchTerm}
                              />
                              <button onClick={() => searchSubmit()}>
                                <i className='fas fa-search' />
                              </button>
                            </div>
                          </Form>
                        );
                      }}
                    </Formik>
                  </div>
                </div>
              </div>
              {/* Modal Search-end */}
            </div>
          </div>
        </div>
      </div>
      <div
        className='d-block d-md-none d-lg-none '
        style={{ marginTop: '110px', padding: '10px' }}
      >
        <div>
          <div className=''>
            <Formik
              initialValues={{
                name: '',
              }}
              validationSchema={Yup.object({
                name: Yup.string().required('Required'),
              })}
              onSubmit={async (values, { setSubmitting, resetForm }) => {
                setSubmitting(true);

                resetForm();
                setSubmitting(false);
              }}
            >
              {(formik) => {
                // console.log(formik);
                return (
                  <Form>
                    <div className='form d-flex align-items-center '>
                      <input
                        type='text'
                        placeholder='Search here...'
                        onChange={(e) => setSearchTerm(e.target.value)}
                        value={searchTerm}
                        className='form-control'
                      />
                      <button
                        className='btn btn-sm btn-primary'
                        onClick={() => searchSubmit()}
                      >
                        <i className='fas fa-search' />
                      </button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
