import React from 'react';
import { URI } from '../../domain/constant';
import moment from 'moment';
import { Link } from 'react-router-dom';

function BlogCard({ blog }) {
  return (
    <div className='blog-post-item'>
      <div className='blog-post-thumb'>
        <Link to={`/blogs/${blog._id}`}>
          <img src={`${URI}${blog.featured_image}`} alt />
        </Link>
      </div>
      <div className='blog-post-content'>
        <span className='date'>
          <i className='far fa-clock' />{' '}
          {blog.createdAt && moment(blog.createdAt).format('MMM DD, YYYY')}
        </span>
        <h2 className='title'>
          <Link to={`/blogs/${blog._id}`}>{blog.title}</Link>
        </h2>
        <div>
          <div dangerouslySetInnerHTML={{ __html: blog.description }}></div>
        </div>
        <div className='blog-post-meta'>
          <div className='read-more'>
            <Link to={`/blogs/${blog._id}`}>
              Read More <i className='fas fa-angle-double-right' />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BlogCard;
