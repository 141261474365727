import React, { useEffect, useState } from 'react';
import Header from '../../components/layout/Header';
import Footer from '../../components/layout/Footer';
import Newsletter from '../home/Newsletter';
import BreadCrumb from '../../components/layout/BreadCrumb';
import MovieCard from '../../components/movies/MovieCard';
import Pagination from '../../components/layout/Pagination';
import { useParams } from 'react-router-dom';
import { useAllMediasByCategory } from '../../shared/hooks/UseMedia';
import { useAllCategorysData } from '../../shared/hooks/UseCategory';

function CategoryArchieve() {
  const params = useParams();
  const [medias_data, getAllMediasByCategory] = useAllMediasByCategory(
    params.category
  );
  const { medias, medias_loading, total_medias, pages, page, category } =
    medias_data;
  const [data] = useAllCategorysData();
  const { categorys } = data;
  const [activeCategory, setActiveCategory] = useState(null);
  useEffect(() => {
    if (categorys) {
      const filterData = categorys.filter(
        (item) => item._id == params.category
      );
      if (filterData && filterData.length > 0) {
        setActiveCategory(filterData[0]);
      }
      getAllMediasByCategory(params.category);
    }
  }, [categorys, params.category, window.location.search]);
  return (
    <div>
      <Header />
      <BreadCrumb
        title={activeCategory && activeCategory.name}
        main_heading={activeCategory && activeCategory.name}
      />
      <section
        className='movie-area movie-bg'
        data-background='img/bg/movie_bg.jpg'
        style={{ backgroundImage: `url(/assets/img/bg/movie_bg.jpg)` }}
      >
        <div className='container'>
          <div className='row align-items-end mb-60'>
            <div className='col-lg-6'>
              <div className='section-title text-center text-lg-left'>
                {/* <span className='sub-title'>ONLINE STREAMING</span> */}
                <h2 className='title'>{category && category.name}</h2>
              </div>
            </div>
          </div>
          <div className='row tr-movie-active'>
            {medias &&
              medias.map((item) => {
                return (
                  <div className='col-xl-3 col-lg-4 col-sm-6 grid-item grid-sizer cat-two'>
                    <MovieCard media={item} />
                  </div>
                );
              })}
          </div>
          <Pagination
            data={medias}
            page={page}
            pages={pages}
            count={total_medias}
            loading={medias_loading}
          />
        </div>
      </section>

      <Newsletter />
      <Footer />
    </div>
  );
}

export default CategoryArchieve;
